export const AUstates = [
  {
    name: "Australian Capital Territory",
    abbreviation: "ACT",
    capital: "Canberra",
    type: "territory",
  },
  {
    name: "New South Wales",
    abbreviation: "NSW",
    capital: "Sydney",
    type: "state",
  },
  {
    name: "Northern Territory",
    abbreviation: "NT",
    capital: "Darwin",
    type: "territory",
  },
  {
    name: "Queensland",
    abbreviation: "QLD",
    capital: "Brisbane",
    type: "state",
  },
  {
    name: "South Australia",
    abbreviation: "SA",
    capital: "Adelaide",
    type: "state",
  },
  {
    name: "Tasmania",
    abbreviation: "TAS",
    capital: "Hobart",
    type: "state",
  },
  {
    name: "Victoria",
    abbreviation: "VIC",
    capital: "Melbourne",
    type: "state",
  },
  {
    name: "Western Australia",
    abbreviation: "WA",
    capital: "Perth",
    type: "state",
  },
];
